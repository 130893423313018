
































import Vue from 'vue';
import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';

export default Vue.extend({
  computed: {
    table() {
      return {
        items: this.items,
        headers: [
          {
            text: this.$i18n.tc('pointofservice.name'),
            value: 'name',
          },
          {
            text: this.$i18n.tc('common.web'),
            value: 'uri',
            filterable: false,
          },
          {
            text: this.$i18n.tc('community.label'),
            value: 'community.name',
          },
          {
            text: this.$i18n.tc('provider.label'),
            value: 'service_provider.name_short',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },
  },

  data: (): {
    items?: PointOfService[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await PointOfServiceUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: PointOfService) {
      await this.$router.push({
        name: 'pointofservice-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'pointofservice-create',
      });
    },
  },
});
